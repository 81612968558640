import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { Routes, RouterModule } from "@angular/router";

import { AdminLayoutComponent } from "./layouts/admin-layout/admin-layout.component";
import { AuthLayoutComponent } from "./layouts/auth-layout/auth-layout.component";
import { RtlLayoutComponent } from "./layouts/rtl-layout/rtl-layout.component";
import { AuthGuard } from "./core/guards/auth.guard";
import { RoleGuard } from "./core/guards/role.guard";

const routes: Routes = [
  {
    path: "",
    redirectTo: "auth/login",
    pathMatch: "full"
  },
  {
    path: "",
    component: AdminLayoutComponent,
    canActivate: [RoleGuard],
    data:{
      roles: ["admin"]
    },
    children: [
      {
        path: "dashboard", 
        loadChildren:
          "./modules/dashboard/dashboard.module#DashboardModule"
      },
      {
        path: "admin",
        loadChildren:
          "./modules/admin/admin.module#AdminModule"
      },
      {
        path: "org_sind",
        loadChildren:
          "./modules/organizaciones/organizaciones.module#OrganizacionesModule"
      },
      {
        path: "tram_sind",
        loadChildren:
          "./modules/tramites/tramites.module#TramitesModule"
      },
      {
        path: "report",
        loadChildren:
          "./modules/reportes/reportes.module#ReportesModule"
      }
    ]
  },
  {
    path: "",
    component: AuthLayoutComponent,
    children: [
      {
        path: "auth",
        loadChildren: "./modules/auth/auth.module#AuthModule"
      }
    ]
  },
  {
    path: "**",
    redirectTo: "auth/login"
  }
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, {
      useHash: true,
      scrollPositionRestoration: "enabled",
      anchorScrolling: "enabled",
      scrollOffset: [0, 64]
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Component, OnInit } from "@angular/core";
import { AuthService } from "src/app/core/services/auth.service";

export interface RouteInfo {
  path: string;
  title: string;
  type: string;
  icontype: string;
  rtlTitle: string;
  collapse?: string;
  isCollapsed?: boolean;
  isCollapsing?: any;
  children?: ChildrenItems[];
}

export interface ChildrenItems {
  path: string;
  title: string;
  smallTitle?: string;
  rtlTitle: string;
  rtlSmallTitle?: string;
  type?: string;
  collapse?: string;
  children?: ChildrenItems2[];
  isCollapsed?: boolean;
}
export interface ChildrenItems2 {
  path?: string;
  smallTitle?: string;
  rtlSmallTitle?: string;
  title?: string;
  rtlTitle: string;
  type?: string;
}
export const ROUTES: RouteInfo[] = [
  {
    path: "/dashboard",
    title: "Inicio",
    type: "link",
    icontype: "tim-icons icon-chart-pie-36",
    rtlTitle: "Inicio"
  },
  {
    path: "/admin",
    title: "Administración",
    type: "sub",
    icontype: "tim-icons icon-settings-gear-63",
    collapse: "pages",
    rtlTitle: "Administración",
    isCollapsed: true,
    children: [
      {
        path: "usuarios",
        rtlTitle: " التسعير ",
        rtlSmallTitle: "ع ",
        title: "Usuarios",
        type: "link",
        smallTitle: "U"
      },
      {
        path: "permisos",
        rtlTitle: "دعمرتل ",
        rtlSmallTitle: "ص",
        title: "Permisos",
        type: "link",
        smallTitle: "P"
      },
      {
        path: "roles",
        rtlTitle: "دعم رتل ",
        rtlSmallTitle: "ص",
        title: "Roles",
        type: "link",
        smallTitle: "R"
      },
      {
        path: "unidades",
        rtlTitle: "دعم رتل ",
        rtlSmallTitle: " ت",
        title: "Unidades involucradas",
        type: "link",
        smallTitle: "UI"
      },
      {
        path: "tramites",
        rtlTitle: "دعم رتل ",
        rtlSmallTitle: " ت",
        title: "Trámites sindicales",
        type: "link",
        smallTitle: "T"
      }
    ]
  },
  {
    path: "/org_sind",
    title: "Organizaciones sindicales",
    type: "link",
    icontype: "tim-icons icon-paper",
    rtlTitle: "dsds"
  },
  {
    path: "/tram_sind",
    title: "Bandeja de entrada",
    type: "link",
    icontype: "tim-icons icon-book-bookmark",
    rtlTitle: "Bandeja de entrada"
  },
  {
    path: "/report",
    title: "Reportes & Seguimiento",
    type: "link",
    icontype: "tim-icons icon-chart-bar-32",
    rtlTitle: "Inicio"
  }
];

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.css"]
})
export class SidebarComponent implements OnInit {
  menuItems: any[];
  data: any[];
  constructor(private authService: AuthService) { }

  ngOnInit() {
    var sortOrder = ['/admin', '/org_sind', '/tram_sind', '/report'];
    var rolArray = [];
    const role = this.authService.user.permisos;

    for (var i = 0; i < role.length; i++) {
      rolArray.push(ROUTES.find(obj => obj.path === '/' + role[i]['nombre']))
    }

    rolArray.sort(function (a, b) {
      return sortOrder.indexOf(a.path) - sortOrder.indexOf(b.path);
    });

    this.menuItems = rolArray;
  }

  home() {
    window.open("/", "_self");
  }
}
